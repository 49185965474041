.navbar {
    /* -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12); */
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);;

}

#navbar-toggler-icon {
    background-color: white;
    color:white;
}

.navbarStyle {
    color: white;
    background-color:#4285f4;
   }

.Navbar.Collapse {
    position: fixed;
    right: 0px;
}